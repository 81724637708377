import React, {useState, useEffect, useRef, Fragment} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import {SlidingPanel} from '../../../Components';
// import {DEFAULT_IMAGE} from '../../../Utils/env';
import SelectedReferUser from '../../../Components/Referral/SelectedReferUser';
import CustomTabs from '../../../Components/CustomTabs';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import {RoiLogo} from '../../../Constants/Images';

const SendInvitation = props => {
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [PhoneNo, setPhonenNo] = useState(props.phoneNumber ? props.phoneNumber : '');
  const [email, setEmail] = useState(props.phoneNumber ? props.phoneNumber : '');
  const [participantArray, setParticipantArraytPhonenNo] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('Business');
  const [contactRelations, setContactRelations] = useState([]);
  const [pocName, setPocName] = useState('');
  const [relationType, setRelationType] = useState('1');
  const [showLoader, setShowLoader] = useState(false);
  const [emailToggle, setEmailToggle] = useState(false);

  const buttonRef = useRef(null);
  // const inviteButton = useRef(null);
  const validateFields = useRef(false);
  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.type_individual[selected_lang],
  ];
  console.log(props.phoneNumber, 'props.phoneNumberprops.phoneNumberprops.phoneNumberprops.phoneNumber');

  const handle = event => {
    const form = event.currentTarget;
    console.log(form.checkValidity(), '==========>form');
    console.log(validateFields.current, '=======>validateFields');
    console.log(firstName, '=======>firstName');
    if (form.checkValidity() === false) {
      console.log(
        validateFields.current,
        'in func validateFields.currentvalidateFields.current',
      );
      console.log(validated, 'setValidated(true);setValidated(true);');
      event.preventDefault();
      event.stopPropagation();
      // if (emailToggle && PhoneNo === '' && email === '') {
      //   errorMessage('Please Add Phone or email');
      // }
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);

      // referOutside();
      // alert('d')
      checkInputType();

      //
    }
  };
  //

  const checkInputType = () => {
    // if (PhoneNo.match(/^\d+$/) || PhoneNo.match(/^\+\d+$/)) {
    //   // alert('Data contains only numbers.');
    //   checkExist(PhoneNo);
    // } else if (PhoneNo.match(/^[A-Za-z]+$/)) {
    //   // alert('Data contains only words (letters).');
    //   referOutside();
    // } else if (PhoneNo.match(/^[A-Za-z\d]+$/)) {
    //   // alert('Data contains both words and numbers.');
    //   referOutside();
    // } else {
    // alert('Data contains other characters or is empty.');

    if (props.addBusinessPOC) {
      addPocInBusinessProfile();
    } else {
      referOutside();
    }

    // }
  };

  const addPocInBusinessProfile = () => {
    let Payload = {
      poc_name: pocName,
      contact:
        /^\d+$/.test(PhoneNo) && !PhoneNo.includes('+')
          ? '+' + PhoneNo
          : PhoneNo,
      contact_email: emailToggle ? email : '',
      linked_user_id:
        props.addBusinessPOC && props.businessProfileId !== ''
          ? props.businessProfileId
          : '',
    };

    commonApi
      .add_single_user_in_linked_user_poc(Payload)
      .then(res => {
        if (res.status === 200 && res.success) {
          successMessage(res.message);
          props.onCloseHitApi && props.onCloseHitApi();
          props.onClose();
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referOutside = type => {
    let Payload = {
      poc_name: pocName,
      contact: type === 'number' ? '+' + PhoneNo : PhoneNo,
      fname: firstName,
      relationship_id: relationType,
      user_type: tabType === 'Business' ? 'business' : 'individual',
      invite_only: emailToggle,
      contact_email: emailToggle ? email : '',
    };
     

    if (type === 'number') {
      if (PhoneNo.includes('+')) {
        // setParticipantArraytPhonenNo(previous => [...previous, Payload]);
        sendnvitationOutIside(Payload);
      } else {
        // alert('')
        let PayloadData = {
          poc_name: pocName,
          contact: '+' + PhoneNo,
          fname: firstName,
          relationship_id: relationType,
          user_type: tabType === 'Business' ? 'business' : 'individual',
          invite_only: emailToggle,
          contact_email: emailToggle ? email : '',
        };
        // setParticipantArraytPhonenNo(previous => [...previous, PayloadData]);

        sendnvitationOutIside(PayloadData);
      }
    } else {
      sendnvitationOutIside(Payload);
    }
    // alert('2')
    if (props.contact) {
      props.setContact(previous => [...previous, Payload]);
    }

    setFirstName('');
    setPhonenNo('');
    setEmail('');
    setPocName('');
    setValidated(false);
    if (props.type === 'schedule') {
      const UpdatedArray = [...participantArray, Payload];
      props.setContact(previous => [...previous, Payload]);
      sendnvitationOutIside(UpdatedArray);
    }

    // setAllBuilders(previous => [...previous, item]);
    // commonApi
    //   .refer_outside(Payload)
    //   .then(res => {
    //     if (res) {
    //       if (res.success === true) {
    //         successMessage(res.message);

    //         props.onClose();
    //       } else {
    //         errorMessage(res.message);
    //       }
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const sendnvitationOutIside = Updated => {
    var Payload;

    
    // props.setContact(previous => [...previous, Updated]);
    if (props.type === 'schedule') {
      Payload = {
        contacts: [Updated],
      };
    } else {
      Payload = {
        contacts: [Updated],
      };
    }
    // console.log(Payload,'PayloadPayloadPayload')
    // return false 

    commonApi
      .send_invitation_outside(Payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (props.type === 'Catalog' || (props.hitApi && props.hitApi)) {
            props.onCloseHitApi && props.onCloseHitApi();
          }
          if (props.setContact) {
            // participantArray.map((item, index) => {
            props.setContact(previous => [...previous, Updated]);
            // });
            setParticipantArraytPhonenNo([]);
          }
          if (props.setCheckUserHitApi) {
            props.setCheckUserHitApi(!props.checkUserHitApi);
          }

          // props.onClose();
        }
        if (res.status === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    setShowLoader(true);
    getContactRelationTypes();
  }, []);
  useEffect(() => {
    setFirstName('');
    setPhonenNo(props.phoneNumber ? props.phoneNumber : '');
    setPocName('');
    setEmail(props.phoneNumber ? props.phoneNumber : '');
  }, [tabType]);

  // const checkExist = number => {
  //   var Payload = {
  //     number: number,
  //   };
  //   commonApi
  //     .check_this_number_exist(Payload)
  //     .then(res => {
  //       if (!res.exist) {
  //         referOutside('number');
  //       } else {
  //         errorMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  console.log(emailToggle, 'emailToggleemailToggle');
  console.log(
    validateFields.current,
    'validateFields.currentvalidateFields.current',
  );
  // how to check bootstrap validtaion on form when user press enter burron in react js
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="referal_page_content">
          <div className="refer_outside add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
              <p className="p-bold m-0 f_600_h">
                {props.snapshotIds ? 'Refer Outside ROI' : 'Add Contact'}
              </p>
            </div>

            {!props.addBusinessPOC && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="create-ticket-tabs w-100">
                  <CustomTabs
                    darkmode
                    tabs={ticketTabs}
                    active={tabType}
                    onSelect={item => {
                      setTabType(item);
                      validateFields.current = false;
                      setValidated(false);
                      setEmailToggle(false);
                    }}
                  />
                </div>
              </div>
            )}
            <Scrollbars className="custom-scroll">
              {tabType === labels.qr_type_business[selected_lang] && (
                <div className="send-refferal-form-wrapper fixed-width-inputs black-head-form-pad-left black-head-form-pad-right mt-2">
                  <Form className="send-refferal-form-input-holder" noValidate validated={validated} onSubmit={handle}>
                    {!props.addBusinessPOC && (
                      <Fragment>
                        <div className="roi-form-input">
                          <Form.Label controlid="validationCustom01">
                            Company Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={firstName}
                            pattern="^\S.*$"
                            required
                            placeholder="Enter Company Name"
                            onChange={e => {
                              setFirstName(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Company Name is required.
                          </Form.Control.Feedback>
                        </div>

                        <div className="roi-form-dropdown mb-3">
                          <Form.Label controlid="validationCustom01">
                            Relationship
                          </Form.Label>
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setRelationType(eventKey);
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {relationType
                                  ? contactRelations.find(
                                      item => item.id === relationType,
                                    )?.relations
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {contactRelations.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id.toString()}
                                    active={item.id === relationType}>
                                    {item.relations}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        POC Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={pocName}
                        pattern="^\S.*$"
                        required={
                          props.addBusinessPOC || validateFields.current
                            ? true
                            : false
                        }
                        placeholder="Enter Name"
                        onChange={e => {
                          setPocName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>

                    {/* <div className="roi-form-input">
                      <Form.Label controlid="validationCustom01">
                        POC Phone or Email
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={PhoneNo}
                        pattern="^\S.*$"
                        // required
                        placeholder="Enter a phone or email "
                        onChange={e => {
                          setPhonenNo(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        POC No is required.
                      </Form.Control.Feedback>
                    </div> */}

                    <div className="roi-form-input">
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label controlid="validationCustom01">
                          POC Phone or Email
                        </Form.Label>

                        {/* <Form.Check
                          style={{paddingBottom: '5px'}}
                          className=""
                          type="switch"
                          id="custom-switch"
                          // label={'Multiple Locations'}
                          value={emailToggle}
                          checked={emailToggle}
                          onClick={() => {
                            setEmailToggle(!emailToggle);
                          }}
                        /> */}
                      </div>

                      <Form.Control
                        type="text"
                        value={PhoneNo}
                        // pattern="Se^\S.*$"
                        required={
                          validateFields.current || props.addBusinessPOC
                            ? true
                            : false
                        }
                        placeholder={
                          // emailToggle
                          //   ? 'Enter phone'
                          //   :
                          'Enter a phone or email '
                        }
                        onChange={e => {
                          setPhonenNo(e.target.value.trim());
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone or email is required.
                      </Form.Control.Feedback>
                    </div>

                    {/* {emailToggle && (
                      <div className="roi-form-input">
                        <Form.Label controlid="validationCustom01">
                          POC Email
                        </Form.Label>

                        <Form.Control
                          type="text"
                          value={email}
                          pattern=".+@.+\..+"
                          required={
                            emailToggle && PhoneNo === '' ? true : false
                          }
                          placeholder="Enter email "
                          onChange={e => {
                            setEmail(e.target.value.trim());
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {emailToggle && email === ''
                            ? 'Phone or email is required.'
                            : 'Invalid Email'}
                        </Form.Control.Feedback>
                      </div>
                    )} */}

                    {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                    {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                    <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}>
                      {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                    </Button>

                    {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                  </Form>
                </div>
              )}

              {tabType === labels.type_individual[selected_lang] && (
                <div className="send-refferal-form-wrapper fixed-width-inputs fix-width-forms black-head-form-pad-left black-head-form-pad-right mt-2">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <div className="roi-form-input">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        pattern="^\S.*$"
                        required
                        placeholder="Enter Name"
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </div>

                    <div className="roi-form-dropdown mb-3">
                      <Form.Label>Relationship</Form.Label>
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setRelationType(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {relationType
                              ? contactRelations.find(
                                  item => item.id === relationType,
                                )?.relations
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {contactRelations.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === relationType}>
                                {item.relations}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="roi-form-input">
                      <div className="d-flex align-items-center justify-content-between">
                        <Form.Label>Phone or Email</Form.Label>

                        {/* <Form.Check
                          style={{paddingBottom: '5px'}}
                          className=""
                          type="switch"
                          id="custom-switch"
                          // label={'Multiple Locations'}
                          value={emailToggle}
                          checked={emailToggle}
                          onClick={() => {
                            setEmailToggle(!emailToggle);
                          }}
                        /> */}
                      </div>

                      <Form.Control
                        type="text"
                        value={PhoneNo}
                        required={validateFields.current}
                        // pattern="Se^\S.*$"
                        // required={emailToggle && email === '' ? true : false}
                        placeholder={
                          // emailToggle
                          //   ? 'Enter phone'
                          //   :
                          'Enter a phone or email '
                        }
                        onChange={e => {
                          setPhonenNo(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone or email is required.
                      </Form.Control.Feedback>
                    </div>

                    {/* {emailToggle && (
                      <div className="roi-form-input">
                        <Form.Label controlid="validationCustom01">
                          POC Email
                        </Form.Label>

                        <Form.Control
                          type="text"
                          value={email}
                          pattern=".+@.+\..+"
                          required={
                            emailToggle && PhoneNo === '' ? true : false
                          }
                          placeholder="Enter email "
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {email === ''
                            ? 'Phone or email is required.'
                            : 'Invalid Email'}
                        </Form.Control.Feedback>
                      </div>
                    )} */}

                    {/* <Form.Group className="fieldset">
                  <span className="use-my-contacts">Use My Contacts</span>
                </Form.Group> */}

                    {/* <div className="invite-persons-heading">
                    <span
                      className={
                        participantArray.length > 0
                          ? 'builder-text as-link'
                          : 'builder-text'
                      }
                      onClick={() => {
                        if (participantArray.length > 0) {
                          setShowComponent('SelectedUsers');
                        }
                      }}>
                      {participantArray.length} Selected
                    </span>
                  </div> */}

                    <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}>
                      {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                    </Button>

                    {/* <input type="submit" value="Submit" onKeyDown={(e) => {if (e.key === 'Enter') handle(e)}} />  */}
                  </Form>
                </div>
              )}
            </Scrollbars>

            <div className="filter-btn-wrapper bg_transparent bottom-0">
              {/* {!props.addBusinessPOC && ( */}
              <div className="text-center pt-1">
                <span
                  // id='hh'
                  className="as-link "
                  style={{
                    color: '#00bf4d',
                  }}
                  onClick={() => {
                    validateFields.current = true;
                    buttonRef.current.click();

                    setEmailToggle(!emailToggle);
                  }}>
                  {tabType === labels.type_individual[selected_lang]
                    ? 'Invite Contact'
                    : 'Invite Business'}
                </span>
              </div>
              {/* )} */}
              <div className="invite-btns p-0 pt-1 text-center">
                <Button
                  type="submit"
                  style={{marginRight:"0px"}}
                  onClick={() => {
                    validateFields.current = false;

                    setEmailToggle(!emailToggle);

                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);
                  }}>
                  {props.snapshotIds
                    ? 'Send Refferral'
                    : tabType === labels.qr_type_business[selected_lang] &&
                      !props.addBusinessPOC
                    ? 'Add Contact'
                    : 'Add Contact'}
                </Button>
              </div>
            </div>
          </div>

          {participantArray.length > 0 && props.id && !props.type && (
            <div className="filter-btn-wrapper pt-3">
              <div className="invite-btns text-center p-0">
                <Button
                  disabled={participantArray.length > 0 ? false : true}
                  onClick={() => {
                    participantArray.forEach((item, index) => {
                      props.setContact(previous => [...previous, item]);
                    });
                    sendnvitationOutIside();

                    // successMessage(' Succesfully add to recipient');
                  }}>
                  Add to recipient
                </Button>
              </div>
            </div>
          )}

          {/* {participantArray.length > 0 && !props.id && !props.type && (
              <div className="filter-btn-wrapper pt-3">
                <div className="invite-btns text-center p-0">
                  <Button
                    disabled={participantArray.length > 0 ? false : true}
                    onClick={() => {
                      if (props.setContact) {
                        participantArray.map((item, index) => {
                          props.setContact(previous => [...previous, item]);
                        });
                      }

                      sendnvitationOutIside();
                    }}>
                    Add Contact
                  </Button>
                </div>
              </div>
            )} */}
        </div>
      </SlidingPanel>

      {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          selectedAllUser={[]}
          users={[]}
          setUsers={[]}
          setSelectedAllUser={[]}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={participantArray}
          setReferOutSide={setParticipantArraytPhonenNo}
        />
      )}
    </>
  );
};

export default SendInvitation;
