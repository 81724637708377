import React, {useState, useEffect, useRef} from 'react';
import {SlidingPanel} from '../../Components';
import {FireIcon, MachineIcon, rewardStar, RoiLogo, timeIcon} from '../../Constants/Images';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Scrollbars from 'react-custom-scrollbars';

const NewAppointmentDetail = props => {
  const [scheduleListing, setScheduleListing] = useState([
    {
      title: 'Service Name',
      description: 'Customer Name',
      location: '7824 Marshal St - Baltimore, MD',
    },
    {
      title: 'Service Name',
      description: 'Customer Name',
      location: '7824 Marshal St - Baltimore, MD',
    },
    {
      title: 'Service Name',
      description: 'Customer Name',
      location: '7824 Marshal St - Baltimore, MD',
    },
    {
      title: 'Service Name',
      description: 'Customer Name',
      location: '7824 Marshal St - Baltimore, MD',
    },
  ]);

  const renderProfileTagsButtons = () => {
    return (
      <>
        <span className="as-link" style={{fontSize: '14px'}}>
          Back
        </span>
      </>
    );
  };

  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        <div
          style={{
            paddingTop: '20px',
          }}
          className="serach-filter-wrapper create-sessionEvents primary_panel_wraper">
            <div
              style={{
                display: 'flex',
              }}>
              <img
                src={DEFAULT_IMAGE}
                alt="Card"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  margin: 10,
                  objectFit: 'cover',
                }}
              />
              <div
                className="task-item"
                style={{
                  width: '100%',
                  padding: '18px 10px',
                }}>
                <h2 className="truncate_2_lines actions-title mb-2">
                  Name Last
                </h2>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div className="d-flex align-items-center session-cam mb-2">
                    <img
                      width={'20px'}
                      height={'20px'}
                      src={rewardStar}
                      alt=""
                    />
                    <p className="p-reg m-0 single-line-text">4.8</p>
                  </div>
                  <div className="d-flex align-items-center session-cam mb-2">
                    <p className="p-reg m-0 single-line-text">Switch Member</p>
                  </div>
                </div>
              </div>
            </div>
              <div className="refferal-list-wrapper mb-3">
                <Scrollbars className="custom-scroll" autoHide>
                  {scheduleListing &&
                    scheduleListing?.length > 0 &&
                    scheduleListing.map((item, index) => (
                      <div
                        key={index}
                        className="refferals-list action-listing as-link mb-3"
                        style={{padding: '0px'}}>
                        <div
                          className="task-item"
                          style={{
                            width: '100%',
                            padding: '18px 10px',
                          }}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <h2 className="truncate_2_lines actions-title mb-2">
                            {item.title}
                          </h2>
                          <img width={'20px'} src={timeIcon} alt=''/>
                          </div>
                          <div className="d-flex align-items-center session-cam mb-2">
                            <p className="p-reg m-0 single-line-text">
                              {item.description}
                            </p>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{alignItems: 'center'}}>
                            <div className="d-flex align-items-center session-cam mb-2">
                              <p className="p-reg m-0 single-line-text">
                                {item.location}
                              </p>
                            </div>
                            <div className="arrow-up">
                            <img width={'20px'} src={MachineIcon} alt=''/>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Scrollbars>
              </div>
        </div>
      </SlidingPanel>
    </>
  );
};
export default NewAppointmentDetail;
