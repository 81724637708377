import React, {useState, useEffect} from 'react';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {fixName, splitStringToArray} from '../../Utils/commonUtils';
import AudioPlayer from './AudioPlayer';
import {Button} from 'react-bootstrap';
import {twillio_icon} from '../../Constants/Images';
import {messagesRecordFound} from '../../Utils/NoRecordConstant';
import NorecordFound from '../NoRecord/NorecordFound';

const CallRecordings = ({subTabType, callRecordingList, showSpinner}) => {
  const [visibleRecordings, setVisibleRecordings] = useState({});
  const [activeId, setActiveId] = useState(null);
  // const [subTabType, setSubTabType] = useState('Recordings');

  const handlePlayPause = (id, index) => {
    const uniqueId = `${id}-${index}`;
    setActiveId(prevId => (prevId === uniqueId ? null : uniqueId));
  };

  // const get_twilio_call = () => {
  //   commonApi
  //     .get_twilio_call()
  //     .then(res => {
  //       if (res.status === 200 && res.call_details.length > 0) {
  //         const filteredData = res.call_details.filter(
  //           item => item.recording_urls !== '',
  //         );
  //         setCallRecordingList(filteredData);
  //         setShowSpinner(false);
  //       }
  //       setShowSpinner(false);

  //     })
  //     .catch(err => console.error('Error fetching Twilio token:', err));
  // };

  // useEffect(() => {
  //   get_twilio_call();
  // }, []);

  const toggleRecordingsVisibility = id => {
    setVisibleRecordings(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
    setActiveId(null);
  };

  const getUserDetails = callDetails => {
    const loginUserId = localStorage.getItem('id'); // Get the logged-in user ID from localStorage
    const receiver = callDetails?.receiver_detail;
    const sender = callDetails?.sender_detail;

    // Check if receiver_id matches the logged-in user's ID
    if (receiver.id !== loginUserId) {
      return {
        firstName: receiver.first_name,
        lastName: receiver.last_name,
        role: receiver.user_role,
        img: receiver.profile_img,
        recording_urls: callDetails.recording_urls,
        phone: callDetails.receiver_phone,
        date: callDetails.created_at,
      };
    } else if (sender.id !== loginUserId) {
      // If receiver doesn't match, check if sender_id matches the logged-in user's ID
      return {
        firstName: sender.first_name,
        lastName: sender.last_name,
        role: sender.user_role,
        img: sender.profile_img,
        recording_urls: callDetails.recording_urls,
        phone: callDetails.sender_phone,
        date: callDetails.created_at,
      };
    } else {
      return null; // If there's no match for both, return null or handle as needed
    }
  };

  // const getFilteredRecordings=(type, callRecordingList)=> {
  //   if (!Array.isArray(callRecordingList)) {
  //     // console.error("callRecordingList must be an array");
  //     return [];
  //   }

  //   switch (type) {
  //     case 'Voice mail':
  //       return callRecordingList.filter(item => item.status === 'voicemail');
  //     case 'Other':
  //       return callRecordingList.filter(item => item.status !== 'voicemail');
  //     default:
  //       // console.warn("Invalid type specified. Returning the full list.");
  //       return callRecordingList; // Return all recordings if no valid type is specified
  //   }
  // }

  // const callRecordingList22 = [
  //   {
  //     id: "1",
  //     receiver_detail: {
  //       first_name: "John",
  //       last_name: "Doe",
  //       profile_img: "https://example.com/profile.jpg",
  //     },
  //     receiver_phone: "Team",
  //     status: "voicemail",  // or some other status like "completed"
  //     recording_urls: "https://example.com/recording1.mp3,https://example.com/recording2.mp3",
  //     created_at: "2024-12-23T10:00:00Z",  // ISO formatted date string
  //   },
  //   {
  //     id: "2",
  //     receiver_detail: {
  //       first_name: "Jane",
  //       last_name: "Smith",
  //       profile_img: "https://example.com/profile2.jpg",
  //     },
  //     receiver_phone: "Team",
  //     status: "completed",  // for non-voicemail items
  //     recording_urls: "https://example.com/recording3.mp3",
  //     created_at: "2024-12-23T12:30:00Z",
  //   },
  //   {
  //     id: "3",
  //     receiver_detail: {
  //       first_name: "Alice",
  //       last_name: "Johnson",
  //       profile_img: "https://example.com/profile3.jpg",
  //     },
  //     receiver_phone: "Customer",
  //     status: "voicemail",
  //     recording_urls: "https://example.com/recording4.mp3,https://example.com/recording5.mp3,https://example.com/recording6.mp3",
  //     created_at: "2024-12-24T15:00:00Z",
  //   },
  //   // More items as needed
  // ];

  return (
    <>
      {showSpinner && <LoaderSpinner />}
      <div className="session-wrapper history-sessions">
        {/* <div className="messages-header mb-2">
          <div className="event-tabs tabs-style-2 mb-2">
            {' '}
            <ul>
              <li>
                <Button
                  className={subTabType === 'Recordings' ? 'active' : ''}
                  onClick={() => {
                    setSubTabType('Recordings');
                  }}>
                  Recordings
                </Button>
              </li>

              <li>
                <Button
                  className={subTabType === 'Voice mail' ? 'active' : ''}
                  onClick={() => {
                    setSubTabType('Voice mail');
                  }}>
                  Voice mail
                </Button>
              </li>
            </ul>
          </div>
        </div> */}
        {callRecordingList && callRecordingList?.length > 0 ? (
          callRecordingList?.map((item, index) => {
            const userDetails = getUserDetails(item); // Call the function with item

            const recordings = splitStringToArray(
              userDetails?.recording_urls || '',
            );
            const isAllVisible = visibleRecordings[item.id];

            return (
              <div
                key={index + 'ASDSAD' + item.id}
                className="events-item cursor-default"
                style={{
                  padding: '11px',
                  marginBottom: '10px',
                  borderRadius: '6px',
                }}>
                <div className="session-heading" style={{alignItems: 'unset'}}>
                  <div className="persons-img-list mb-0 d-flex align-items-center">
                    <div className="img-item mb-0">
                      <img
                        src={userDetails.img ? userDetails.img : DEFAULT_IMAGE}
                        alt=""
                        style={{width: '60px', height: '60px'}}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '154px',
                        }}>
                        <div className="m-1">
                          {fixName(userDetails.firstName, userDetails.lastName)}
                        </div>
                        <div className="event-date fs-8 cursor-default">
                          <Moment format="HH:mm A">{userDetails.date}</Moment>
                        </div>
                      </div>
                      {userDetails?.role && (
                        <div className="m-1">{userDetails?.role}</div>
                      )}
                      {userDetails?.phone && (
                        <div className="m-1">{userDetails?.phone}</div>
                      )}
                    </div>
                  </div>
                </div>

                {(isAllVisible ? recordings : recordings.slice(0, 1)).map(
                  (url, idx) => {
                    const uniqueId = `${item.id}-${idx}`;
                    return (
                      <div key={idx}>
                        <AudioPlayer
                          src={url}
                          index={idx}
                          isPlaying={activeId === uniqueId}
                          onPlayPause={() => handlePlayPause(item.id, idx)}
                        />
                      </div>
                    );
                  },
                )}

                <div className="session-heading">
                  <div className="event-titles as-link">
                    {recordings.length > 1 && (
                      <button
                        style={{color: '#5ABD42', fontSize: '14px'}}
                        onClick={() => toggleRecordingsVisibility(item.id)}>
                        {isAllVisible ? 'Show Less' : 'View More'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <NorecordFound noRecordData={messagesRecordFound} />
        )}
      </div>
    </>
  );
};

export default CallRecordings;
