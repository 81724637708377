import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import ReferNotes from './ReferNotes';
import SelectedReferUser from './SelectedReferUser';
import SlidingPanel from '../SlidingPanel';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {fixName} from '../../Utils/commonUtils';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {ENV_HOST} from '../../Utils/env';
import {useLocation} from 'react-router-dom';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CustomTabs from '../CustomTabs';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import Invite from '../SingleComponent/Invite';
import {RoiLogo, ShareablesBlackIcon} from '../../Constants/Images';
import CustomModal from '../CustomModal';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import AddCC from '../SingleComponent/AddCC';

const ReferProfile = props => {
  const [refer, setRefer] = useState('');
  const [referId, setReferId] = useState('');
  const [builders, setBuilders] = useState('');
  const [showLoader, setshowLoader] = useState(false);
  const [intrestedFor, toggleIntrestedFor] = useState('');
  const [allBuilders, setAllBuilders] = useState([]);
  const [notes, setNotes] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('Details');
  const [profileId, setProfileId] = useState('');
  const [contact, setContact] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('1');

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');
  const [companyData, setCompanyData] = useState({
    name: '',
    industry: '1',
    no_of_employes: '',
    billing_email: '',
    location: [],
    id: '',
    cc_user_ids: [],
    show_cc_user_array: [],
    category_ids: '',
  });

  const [beingReferrred, setBeingReferrred] = useState([]);
  const locationWeb = useLocation();

  const handleReferel = () => {
    const filterReferrel = companyData?.show_cc_user_array?.filter(
      item => item.id === companyData?.cc_user_ids[0],
    );
    setBeingReferrred(filterReferrel);
  };

  useEffect(() => {
    if (props.type === 'selectRefer') {
      handleReferel();
    }
  }, [companyData]);

  const submit = () => {
    if (builders.length === 0) {
      errorMessage('Select  recipient');
    } else if (referId === '') {
      errorMessage('Select purpose ');
    } else if (props.type === 'selectRefer' && !beingReferrred[0]?.id) {
      errorMessage('Select Referred');
    } else {
      setshowLoader(true);
      referProfile();
    }
  };

  const referProfile = () => {
    var payloadReferProfile = {
      purpose_id: referId,
      referred_id:
        props.type === 'selectRefer'
          ? beingReferrred[0]?.id
          : props.userData?.id,
      refer_notes: notes,
      user_ids: builders,
      contacts: contact,
      status: selectedStatus,
      // [{ contact: '', fname: '', lname: '' }, { contact: '', fname: '', lname: '' }]
    };
    commonApi
      .refer_profile(payloadReferProfile)
      .then(res => {
        if (res.status === 200) {
          amplitudeEvent('REFER_PERSON');
          props.onClose();
          successMessage(res.message);
        }
        setshowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getRefer = () => {
    var payloadRefer = {
      type: 'create',
    };
    commonApi
      .get_refer_purpose(payloadRefer)
      .then(res => {
        if (res.status === 200) {
          setRefer(res.purpose);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const name = props.userData.unique_user_name;
  // const lowerName = name.toLowerCase();

  // const lowerName = name.toLowerCase();
  let lowerName = '';
  let creator_id = '';
  let companyNameFromLocal = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  // Check if lowerName is not empty and has at least one item
  if (companyNameFromLocal.length > 0) {
    // Access the first item in the array and get the company_name property
    let companyName = companyNameFromLocal[0].company_name;
    creator_id = companyNameFromLocal[0]?.created_by;
    // Replace spaces with hyphens
    lowerName = companyName.replace(/\s+/g, '-');

    console.log(lowerName); // Output the formatted company name
  }

  const storeReferral = () => {
    var payloadAllUser = {
      referral_id:
        props.type === 'selectRefer'
          ? beingReferrred[0]?.id
          : props.userData?.id,
      referral_key:
        'https://' +
        ENV_HOST +
        'roilevelup.com/' +
        lowerName +
        '/profile?id=' +
        (props.type === 'selectRefer'
          ? beingReferrred[0]?.id
          : props.userData?.id),
      referral_type: 'profile',
    };

    commonApi
      .store_referral(payloadAllUser)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const excludeFollowersList = qry => {
    var payloadFolower = {
      chat_room_id: '',
      circle_id: '',
      opportunity_id: '',
      search_query: qry,
      user_id:
        props.type === 'selectRefer'
          ? beingReferrred[0]?.id
          : props.userData?.id,
    };
    commonApi
      .excluded_followers_list(payloadFolower)
      .then(res => {
        setshowLoader(false);
        if (res.status === 200) {
          // setFollowing(res.data.filter((item) => !builders.includes(item.id)));
          // setNewFollowers(res.data);
          // setFollowing(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data.filter(item => !builders.includes(item.id)));
          // setNewFollowers(res.data);
          // setFollowing(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    getRefer();
    excludeFollowersList();
    setshowLoader(true);
    handleNotificationDirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const mergedArray = [...following, ...newFollowers];

  const handleNotificationDirection = () => {
    const {state} = locationWeb;

    if (state && state.typeIs === 'referEvent') {
    }
    if (state && state.typeIs === 'referQuestion') {
    }
    if (state && state.typeIs === 'feedback') {
    }
    if (state && state.typeIs === 'referOpportunity') {
    }
    if (state && state.typeIs === 'referSession') {
    }
    if (state && state.typeIs === 'referBuilder') {
    }
  };

  // useEffect(() => {
  //   setFollowing(newFollowers.filter((item) => !builders.includes(item.id)));
  //   setAllBuilders(newFollowers.filter((item) => builders.includes(item.id)));
  // }, [builders]);

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!builders.includes(miniItem.id)) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const handleProfileComponent = userId => {
    if(props.type === 'selectRefer'){
    setShowComponent('select-referrel');
    }else{
      setShowComponent('profileComponent');
      setProfileId(userId);
    }
  };

  // useEffect(() => {
  //   if (builders.length === 0) {
  //     setShowSlectedUser('');
  //   }
  // }, [builders]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const dealStatus = [
    {
      status: '1',
      color: '#3597C2',
    },
    {
      status: '2',
      color: '#FEBE13',
    },

    {
      status: '3',
      color: '#49B038',
    },
  ];

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const getCompany = () => {
    var payload = {
      company_users: true,
    };
    commonApi
      .get_company(payload)
      .then(res => {
        setTimeout(() => {
          setshowLoader(false);
        }, 1000);

        if (res.data?.length > 0) {
          const activeItems = res.data.filter(
            item => item.user_status === 'active',
          );
          console.log('activeItems', activeItems);

          setCompanyData({
            name: activeItems[0].company_name,
            industry:
              activeItems[0].company_type_id === ''
                ? '1'
                : activeItems[0].company_type_id,
            no_of_employes: activeItems[0].no_of_members,
            billing_email: activeItems[0].company_email,
            location: activeItems[0].locations,
            id: activeItems[0].id,
            cc_user_ids:
              activeItems[0].cc_user_ids === ''
                ? []
                : activeItems[0].cc_user_ids.split(','),
            show_cc_user_array: activeItems[0].company_users,
            category_ids: activeItems[0].category_ids,
          });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const renderReferProfileButtons = profile => (
    <>
      <div className="ganjaaaa">
        <div>
          <div
            className="share-btn-black-img-holder as-link"
            onClick={() => {
              if (props.type === 'selectRefer') {
                if (beingReferrred.length > 0) {
                  setProfileId('')
                  setShowComponent('profileComponent');
                } else {
                  errorMessage('Select Referred');
                }
              } else {
                setProfileId('')
                setShowComponent('profileComponent');
              }
            }}>
            <img
              style={{filter: 'brightness(6)'}}
              src={ShareablesBlackIcon}
              alt=""
              className="as-link"
              // onClick={() => {
              //   setShowCompanyShareModel('show_model');
              // }}
            />
            <span>Share Externally</span>
          </div>
        </div>
      </div>
    </>
  );

  const renderProjeectButtons = profile => {
    return (
      <>
        <img
          src={RoiLogo}
          // onClick={() => setEditProject(false)}
          alt=""
          style={{cursor: 'pointer'}}
        />
      </>
    );
  };

  const linkForShare =
    'https://' +
    ENV_HOST +
    'roilevelup.com/' +
    (props.type === 'selectRefer' ? beingReferrred[0]?.id : props.userData?.id);
  return (
    <>
      <SlidingPanel
        header={true}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        renderData={renderReferProfileButtons}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => props.onClose()}
        marginTop={props.marginTop ? props.marginTop : false}>
        <div
          className="reffer-profile-scroll-block reffer-profile-scroll-holder fixed-width-inputs "
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}>
          <div className="d-flex align-items-center justify-content-center  w-100">
            <div className="create-ticket-tabs text-center">
              <CustomTabs
                darkmode
                tabs={['Details', 'Recipient']}
                active={tabType}
                onSelect={item => {
                  setTabType(item);
                  if (item === 'Details' && inviteQry !== '') {
                    setInviteFollowers(followersList);
                    setInviteQry('');
                  }
                }}
              />
            </div>
          </div>
          <Scrollbars>
            <div className="fix-width-refferal-form black-head-form-pad-left">
              {/* {showLoader && <LoaderSpinner />} */}
              <div className="sidebar-heading-wrapper">
                {/* <div className="heading">
                  <h2>
                    {fixName(
                      props.userData.first_name,
                      props.userData.last_name,
                    )}
                  </h2>
                </div> */}
                {/* <div className="persons-img-list m-0">
                  <div
                    className="img-item as-link m-0"
                    onClick={() => {
                      handleProfileComponent(props.userData.id);
                    }}>
                    <img
                      className="m-0"
                      src={
                        props.userData.profile_img
                          ? props.userData.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                    />
                  </div>
                </div> */}

                {/* <div className="search-tabs-icons">
                  <span
                    className="icon as-link"
                    onClick={() => {
                      setShowComponent('ReferNotes');
                    }}>
                    <i className="fa-sharp fa-solid fa-book"></i>
                  </span>
                </div> */}
              </div>

              {tabType === 'Details' && (
                <div className="intrested-for-outer-wrapper">
                  {props.type !== 'eventRefer' && (
                    <div className="intrested-for-wrapper">
                      <Form.Group className="">
                        <Form.Label>
                          {' '}
                          {/* {fixName(
                            props.userData.first_name,
                            props.userData.last_name,
                          )} */}
                          <h2
                            style={{
                              marginBottom: '15px',
                            }}>
                            Being Referred
                          </h2>
                          <div 
                          onClick={() =>
                            props.type === 'selectRefer'
                              ? handleProfileComponent(beingReferrred[0]?.id)
                              : handleProfileComponent(props.userData?.id)
                          }
                          className="messages-profile-heading message-profile-heading-nospace no-space">
                            <div
                              className="profile-img as-link"
                              >
                              {' '}
                              <img
                                style={{
                                  boxShadow: 'unset',
                                }}
                                src={
                                  props.type === 'selectRefer'
                                    ? (beingReferrred[0]?.profile_img ||
                                      '/static/media/add-contact.b735aa04e0f78dbb03db70fc733983bc.svg')
                                    : (props.userData?.profile_img || DEFAULT_IMAGE)
                                }
                                alt=""
                              />
                            </div>
                            <div className="profile-content">
                              <div className="user-name mb-0">
                                <h2 className="as-link">
                                  {' '}
                                  {props.type === 'selectRefer'
                                    ? ((fixName(beingReferrred[0]?.first_name, beingReferrred[0]?.last_name) === 'no Name')
                                      ? 'Add Referred'
                                      : fixName(beingReferrred[0]?.first_name, beingReferrred[0]?.last_name))
                                    : fixName(props.userData?.first_name, props.userData?.last_name)
                                    }
                                </h2>
                              </div>
                              <div className="profile-links mt-1 customized-profile-links">
                                <span></span>
                              </div>
                            </div>
                          </div>
                          <h2
                            style={{
                              marginBottom: '15px',
                            }}>
                            Reason
                          </h2>
                        </Form.Label>

                        <div
                          className="d-flex flex-column"
                          style={{
                            maxWidth: '100%',
                            justifyContent: 'center',
                            margin: '0 auto',
                            marginLeft: '0px',
                          }}>
                          {Object.entries(refer).length > 0 &&
                            refer.map((item, index) => (
                              <Button
                                key={`refer-item-${index}`}
                                className={
                                  intrestedFor === item.title ? 'active' : ''
                                }
                                id={item.id}
                                onClick={e => {
                                  toggleIntrestedFor(item.title);
                                  setReferId(e.target.id);
                                }}>
                                {item.title}
                              </Button>
                            ))}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                  <div>
                    <h2>Likelihood</h2>
                  </div>

                  <div className="likelihood_color_flex mb-2">
                    {dealStatus.map((status, index) => (
                      <div
                        style={{
                          border:
                            selectedStatus === status.status
                              ? '3px solid #424242'
                              : '',
                        }}
                        className={`deal_status_border ${
                          selectedStatus === status.status ? 'white' : ''
                        }  `}>
                        <div
                          key={index}
                          className="deal_status"
                          style={{
                            backgroundColor: status.color,
                          }}
                          onClick={() => {
                            setSelectedStatus(status.status);
                          }}></div>
                      </div>
                    ))}
                  </div>
                  <div className="serach-filter-wrapper">
                    <div className="sidebar-heading-wrapper">
                      <div className="heading">
                        <label>Notes (optional)</label>
                      </div>
                    </div>

                    <div className="">
                      <div className="form-outline">
                        <Form>
                          <textarea
                            // disabled={props.disabled}
                            className="form-control"
                            id="textAreaExample1"
                            rows="12"
                            placeholder="Write a note..."
                            value={notes}
                            onChange={e => {
                              setNotes(e.target.value);
                            }}></textarea>
                        </Form>
                      </div>
                    </div>
                    <div className="invite-persons-heading mt-3">
                      <span
                        className="builder-text as-link"
                        onClick={() => {
                          if (props.type === 'selectRefer') {
                            if (beingReferrred?.length > 0) {
                              navigator.clipboard.writeText(
                                'https://' +
                                  ENV_HOST +
                                  'roilevelup.com/' +
                                  lowerName +
                                  '/' +
                                  creator_id +
                                  '/profile?id=' +
                                  beingReferrred[0]?.id,
                              );
                              storeReferral();
                              successMessage(labels.copy_link[selected_lang]);
                            } else {
                              errorMessage('Select Referred');
                            }
                          } else {
                            navigator.clipboard.writeText(
                              'https://' +
                                ENV_HOST +
                                 'roilevelup.com/' +
                                lowerName +
                                '/' +
                                creator_id +
                                '/profile?id=' +
                                props.userData?.id,
                            );
                            storeReferral();
                            successMessage(labels.copy_link[selected_lang]);
                          }
                        }}>
                        Copy Profile Link
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {tabType === 'Recipient' && (
                <>
                  <div className="refferal-list-wrapper">
                    {inviteLoader && (
                      <div className="invite_loader_hieght">
                        <LoaderSpinner className={'curved-spinner-bg'} />
                      </div>
                    )}
                    {!inviteLoader && (
                      <div className="invite-persons-heading justify-content-center">
                        <span
                          className="builder-text as-link "
                          onClick={() => {
                            if (props.type === 'selectRefer') {
                              if (beingReferrred.length > 0) {
                                setShowComponent('ReferOutSideBuild');
                                setTimeout(() => {
                                  if (
                                    contact.length === 0 &&
                                    inviteQry !== ''
                                  ) {
                                    setInviteFollowers(followersList);
                                    setInviteQry('');
                                  }
                                }, 1000);
                              } else {
                                errorMessage('Select Referred');
                              }
                            } else {
                              setShowComponent('ReferOutSideBuild');
                              setTimeout(() => {
                                if (contact.length === 0 && inviteQry !== '') {
                                  setInviteFollowers(followersList);
                                  setInviteQry('');
                                }
                              }, 1000);
                            }
                          }}>
                          Refer Outside ROI
                        </span>
                      </div>
                    )}

                    <Invite
                      type={'refer'}
                      moderator={builders}
                      setModerator={setBuilders}
                      selectedModData={allBuilders}
                      setSelectedModData={setAllBuilders}
                      contact={contact}
                      setContact={setContact}
                      originalDataOfMod={originalDataOfMod}
                      setOriginalDataOfMod={setOriginalDataOfMod}
                      handleCircle={handleCircle}
                      forApiHit={showComponent}
                      onClick={() => {
                        setShowComponent('inviteUser');
                        setTimeout(() => {
                          if (contact.length === 0 && inviteQry !== '') {
                            setInviteFollowers(followersList);
                            setInviteQry('');
                          }
                        }, 1000);
                      }}
                      showLoader={inviteLoader}
                      setshowLoader={setInviteLoader}
                      followers={inviteFollowers}
                      setFollowers={setInviteFollowers}
                      setFollowersList={setFollowersList}
                      followersList={followersList}
                      setSearchQry={setInviteQry}
                      searchQry={inviteQry}
                    />
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
          {tabType === 'Details' && (
            <div
              className="filter-btn-wrapper invite-btns text-center px-0 bg_transparent bottom-0"
              style={{marginLeft: '0px'}}>
              <Button type="submit" onClick={submit}>
                Send Referral
              </Button>
            </div>
          )}
        </div>
      </SlidingPanel>

      {!profileId && showComponent === 'profileComponent' && (
        <CustomModal
          position="bottom"
          className={'share-links-modal'}
          onClose={() => setShowComponent('')}
          // setTabType={setTabType}
        >
          <div className="camera-options-list w-100 social-links-modal">
            <ul>
              <li className="as-link">
                <FacebookShareButton url={linkForShare} title={false}>
                  <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
              </li>
              <li className="as-link">
                <WhatsappShareButton url={linkForShare}>
                  <i className="fa-brands fa-whatsapp"></i>
                </WhatsappShareButton>
              </li>
              <li className="as-link">
                <EmailShareButton url={linkForShare}>
                  <i className="fa-solid fa-envelope"></i>
                </EmailShareButton>
              </li>
              <li className="as-link">
                <TwitterShareButton url={linkForShare}>
                  <i className="fa-brands fa-twitter"></i>
                </TwitterShareButton>
              </li>
              <li className="as-link">
                <LinkedinShareButton url={linkForShare}>
                  <i className="fa-brands fa-linkedin"></i>
                </LinkedinShareButton>
              </li>
            </ul>
          </div>
        </CustomModal>
      )}
      {showComponent === 'profileComponent' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
      {builders.length + contact.length > 0 &&
        showComponent === 'SelectedUsers' && (
          <SelectedReferUser
            selectedAllUser={allBuilders}
            users={builders}
            setUsers={setBuilders}
            setSelectedAllUser={setAllBuilders}
            onClose={() => setShowComponent('')}
            alreadyAddedUser={[]}
            referOutSide={contact}
            setReferOutSide={setContact}
          />
        )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showComponent === 'ReferNotes' && (
        <ReferNotes
          setNotes={setNotes}
          notes={notes}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'ReferOutSideBuild' && (
        <SendInvitation
          id={
            props.type === 'selectRefer'
              ? beingReferrred[0]?.id
              : props.userData.id
          }
          referType={'profile'}
          setContact={setContact}
          onClose={() => setShowComponent('')}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => setShowComponent('')}
        />
      )}

      {showComponent === 'select-referrel' && (
        <SlidingPanel
          hidePanel={() => {
            setShowComponent('');
          }}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
          }}
          renderData={renderProjeectButtons}
          showBlackHeader={true}
          lastDivStyle={{
            borderRadius: '35px',
            background: '#F7F6F2',
          }}>
          <div
            className="serach-filter-wrapper create-sessionEvents"
            style={{
              paddingTop: '20px',
            }}>
            <div className="referal_page_content">
              <AddCC
                companydetailApiData={companyData}
                setCompanydetailApiData={setCompanyData}
                onClose={() => {
                  setShowComponent('');
                }}
                type={'referrel'}
              />
            </div>
          </div>
        </SlidingPanel>
      )}
    </>
  );
};
export default ReferProfile;
