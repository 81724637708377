import React, {useState, useEffect, useRef, useCallback} from 'react';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import $ from 'jquery';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo} from '../../Constants/Images';
import {Button, Form} from 'react-bootstrap';
const ProfileMap = props => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [locationCity, setLocationCity] = useState('');
  const [locationState, setLocationState] = useState('');
  const [error, setError] = useState('');
  const [location, setLocation] = useState('');
  const googleMapRef = useRef(null);
  let googleMap = null;
  const [showLoader, setshowLoader] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        // Get the location city and state using the Google Maps Geocoding API
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s`,
        )
          .then(response => response.json())
          .then(data => {
            //
            if (data.status === 'OK') {
              console.log(data, '-------->daata');
              const cityResult = data.results[0].address_components.find(
                component => component.types.includes('locality'),
              );
              const countryResult = data.results[0].address_components.find(
                component => component.types.includes('country'),
              );

              const city = cityResult ? cityResult.long_name : '';
              const country = countryResult ? countryResult.long_name : '';

              $('.css-1uccc91-singleValue').html(city + ', ' + country);
              setLocation('');
              setLocationCity(city);
              setLocationState(country);
              // Find the city and state in the address components
              // for (const component of data.results[0].address_components) {
              //   if (component.types.includes('locality')) {
              //
              //     setLocationCity(component.long_name);
              //   } else if (
              //     component.types.includes('administrative_area_level_1')
              //   ) {
              //     setLocationState(component.short_name);
              //   }
              // }
            } else {
              setError('Error getting location city and state');
            }
          })
          .catch(error => setError(error.message));
      },
      error => setError(error.message),
      {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
    );
  }, [currentLocation]);

  useEffect(() => {
    bug();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    googleMap = initGoogleMap();

    var bounds = new window.google.maps.LatLngBounds();

    if (latitude && longitude) {
      let aa = {lat: latitude, lng: longitude};
      //
      const marker = createMarker(aa);
      bounds.extend(marker.position);
    }

    googleMap.fitBounds(bounds); // the map to contain all markers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude]);

  const resetLocation = () => {
    // setLatitude("");
    setLocation('');
    setLocationCity('');
    // setLongitude("");
    setLocationState('');
    $('.css-1uccc91-singleValue').html('Type city and state');
  };
  const clearUserLocation = () => {
    setshowLoader(true);
    var work = {
      address: '',
      latitude: '',
      longitude: '',
      type: props.home ? 'home' : 'current',
    };
    commonApi
      .clear_user_location(work)
      .then(res => {
        if (res.status === 200) {
          if (props.home) {
            props.profile.data.home_location = '';
          } else {
            props.profile.data.current_location = '';
          }
          successMessage(res.message);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const updateUserLocation = (longName, shortname, lat, lng) => {
    let perfectAddress =
      longName && longName !== '' ? longName + ', ' + shortname : shortname;
    setshowLoader(true);
    var work = {
      address:
        perfectAddress && perfectAddress !== ', '
          ? perfectAddress
          : location.label,
      latitude:
        (perfectAddress && perfectAddress !== ', ') || location.label
          ? lat
          : '',
      longitude:
        (perfectAddress && perfectAddress !== ', ') || location.label
          ? lng
          : '',
      type: props.home ? 'home' : 'current',
    };
    commonApi
      .update_user_location(work)
      .then(res => {
        if (res.status === 200) {
          if (props.home) {
            props.profile.data.home_location = res.user.home_location;
          } else {
            props.profile.data.current_location = res.user.current_location;
          }

          props.onClose();
          successMessage(res.message);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateCompanyLocation = (longName, shortname, lat, lng) => {
    // let perfectAddress = longName + ', ' + shortname;

    // props.setLocation(
    //   perfectAddress && perfectAddress !== ', '
    //     ? perfectAddress
    //     : location.label,
    // );
    // props.setLongitude(lng ? lng : '');
    // props.setLatitude(lat ? lat : '');

    props.onClose();

    setshowLoader(false);
  };

  // initialize the google map
  const initGoogleMap = useCallback(() => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {lat: 59.2987638, lng: 17.9917639},
      zoom: 11,
    });
  }, []);

  // create marker on google map
  const createMarker = markerObj =>
    new window.google.maps.Marker({
      position: {lat: markerObj.lat, lng: markerObj.lng},
      map: googleMap,
      icon: {
        path: 'M 0,0 C 0,-10 10,-20 20,-20 C 30,-20 40,-10 40,0 C 40,10 30,20 20,20 C 10,20 0,10 0,0 ',
        fillColor: '#146C94',
        fillOpacity: 1,
        strokeColor: 'white',
        strokeWeight: 1,
        scale: 0.4,
      },
    });
  // map end
  // const bug = () => {
  //   // extractCityAndState(location.label)
  //   var place_id = !location ? '' : location.value.place_id;
  //   if (place_id) {
  //     geocodeByPlaceId(place_id)
  //       .then(results => getLatLng(results[0]))
  //       .then(({lat, lng}) => {
  //         setLongitude(lng);
  //         setLatitude(lat);
  //         console.log(lng,lat,'latlatlatlat')
  //         navigator.geolocation.getCurrentPosition(
  //           position => {
  //             setLatitude(lat);
  //             setLongitude(lng);

  //             // Get the location city and state using the Google Maps Geocoding API
  //             fetch(
  //               `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s`,
  //             )
  //               .then(response => response.json())
  //               .then(data => {
  //                 if (data.status === 'OK') {
  //                   // Find the city and state in the address components
  //                   console.log(data.results,'123213123')
  //                   for (const component of data.results[0]
  //                     .address_components) {
  //                     if (component.types.includes('locality')) {
  //                       setLocationCity(component.long_name);

  //                     }
  //                     if (!component.types.includes('locality')) {
  //                       if (
  //                         component.types.includes(
  //                           'administrative_area_level_2',
  //                         )
  //                       ) {
  //                         setLocationState(component.short_name);
  //                       }else if (component.types.includes("country")){
  //                         setLocationCity(component.long_name);
  //                       }
  //                     }
  //                     if (
  //                       component.types.includes('administrative_area_level_1')
  //                     ) {
  //                       setLocationState(component.short_name);
  //                     }
  //                   }
  //                 } else {
  //                   setError('Error getting location city and state');
  //                 }
  //               })
  //               .catch(error => setError(error.message));
  //           },
  //           error => setError(error.message),
  //           {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
  //         );
  //       });
  //   }
  // };
  const bug = () => {
    if (!location) return;

    const place_id = location.value.place_id;
    if (!place_id) return;

    geocodeByPlaceId(place_id)
      .then(results => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setLongitude(lng);
        setLatitude(lat);

        navigator.geolocation.getCurrentPosition(
          position => {
            // Fetch location city and state using the Google Maps Geocoding API
            fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s`,
            )
              .then(response => response.json())
              .then(data => {
                if (data.status === 'OK') {
                  let city = '';
                  let state = '';

                  for (const result of data.results) {
                    for (const component of result.address_components) {
                      if (component.types.includes('locality')) {
                        city = component.long_name;
                      }
                      if (
                        component.types.includes('administrative_area_level_1')
                      ) {
                        state = component.short_name;
                      }
                      if (city && state) break; // Exit the loop if both city and state are found
                    }
                    if (city && state) break; // Exit the loop if both city and state are found
                  }

                  setLocationCity(city);
                  setLocationState(state);

                  if (!city) {
                    setError('City not found');
                  }

                  if (!state) {
                    setError('State not found');
                  }
                } else {
                  setError('Error getting location city and state');
                  setLocationCity('');
                  setLocationState('');
                }
              })
              .catch(error => {
                setError(error.message);
                setLocationCity('');
                setLocationState('');
              });
          },
          error => {
            setError(error.message);
            setLocationCity('');
            setLocationState('');
          },
          {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
        );
      });
  };

  const renderHeader = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {/* <img src={RoiLogo} alt="" /> */}
        <div className="d-flex flex-column gap-1" style={{fontSize: '15px'}}>
          <span
            className="as-link f_600 text_green "
            onClick={() => {
              clearUserLocation();
              resetLocation();
            }}>
            Clear Location
          </span>

          <span>
            {props.home ? 'Where do you live? ' : 'Where are traveling for?'}
          </span>
        </div>
      </>
    );
  };

  let jugar = locationCity
    ? locationCity + ', ' + locationState
    : 'Type city and state';

  console.log(locationCity, '----------->locationCity');

  console.log(location, '-------->location');
  console.log(jugar,'jugarjugar------>')

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log(location,'locationlocationlocationlocation')
  //     const geocoder = new window.google.maps.Geocoder();

  //     geocoder.geocode(
  //       {address: locationCity + ', ' + locationState},
  //       (results, status) => {
  //         if (status === 'OK') {
  //           // Extract city and country information
  //           const city = results[0].address_components.find(component =>
  //             component.types.includes('locality'),
  //           )?.long_name;
  //           const country = results[0].address_components.find(component =>
  //             component.types.includes('country'),
  //           )?.long_name;

  //           // Use the city and country information as needed
  //   console.log(results,'resultsresultsresults')
  //   console.log(city,country,'countrycountrycountry')
  //           setLocationCity(city);
  //           setLocationState(country);
  //         } else {
  //           console.error(`Geocode failed: ${status}`);
  //         }
  //       },
  //     );
  //   }, 2000);

  // }, [location])

  // console.log(locationCity,'locationCity')
  // console.log(locationState,'locationState')
  // console.log(location,'location')
  return (
    <>
      {showLoader && <LoaderSpinner error={error} />}
      <SlidingPanel
        // profileMapPadding={{padding:'25px 15px 0px 15px'}}
        renderData={renderHeader}
        headerPadding={'15px 24px'}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#f7f6f2',
          padding: '15px 15px 0px 15px',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          // if(location && location.label){
          updateUserLocation(locationCity, locationState, latitude, longitude);
          // }
          updateCompanyLocation(
            locationCity,
            locationState,
            latitude,
            longitude,
          );
        }}>
        <Scrollbars
         renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
         renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}
        >     
          <div className="add_location user_location">
            <div className="invite-persons-list text-right">
              {/* <span
          className="close as-link"
          onClick={() => {
            updateUserLocation(locationCity , locationState, latitude,longitude);
          }}>
          <i className="fas fa-times" />
        </span> */}
            </div>
            {/* <div className='d-flex flex-column gap-2'>
          <span
            className="as-link f_600 text_green "
            onClick={() => {
              clearUserLocation();
              resetLocation();
            }}>
            Clear Location
          </span>
         
          <span>
            {props.home ? 'Where do you live? ' : 'Where are traveling for?'}
          </span>
          </div> */}
            <div className="search-form map-custom-search-form d-flex">
              <div className="flex-1 form-control">
                <GooglePlacesAutocomplete
                  location
                  selectProps={{
                    location,
                    onChange: setLocation,
                    name: 'city',
                    placeholder: jugar,
                    noOptionsMessage: () => null,
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  setCurrentLocation(!currentLocation);
                }}>
                <i className="icon-map-pin" />
              </Button>
            </div>

            <div
              className="text-center"
              ref={googleMapRef}
              style={{width: '100%', height: 500}}
            />
          </div>

          {/* <p>Latitude: {latitude}</p>
        <p>Longitude: {longitude}</p>
        <p>Location city: {locationCity}</p>
        <p>
          Location state: {locationState}
        </p> */}
        </Scrollbars>
      </SlidingPanel>
    </>
  );
};

export default ProfileMap;
