import React from 'react';
import {Form, Accordion} from 'react-bootstrap';

const CustomAccordion = props => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header
            className={`position-relative ${
              props.type === 'allMenus' ? '' : 'co-founder-accord-btn-holder'
            }`}>
            {' '}
            {/* <div className="d-flex justify-content-between" style={{width : '-webkit-fill-available'}}>
              <span>{props.item.menu_name}</span>
              {props.type === 'allMenus' && (
                <span>{props.item.role_alias}</span>
              )}
            </div> */}
            {/* <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
  <div className="d-flex flex-column justify-content-center" style={{ flex: 1, wordBreak: 'break-word' }}>
    {props.item.menu_name}
  </div>
  {props.type === 'allMenus' && (
    <div className="d-flex flex-column justify-content-center" style={{ flex: 1, textAlign: 'center', wordBreak: 'break-word' }}>
      {props.item.role_alias}
    </div>
  )}
</div> */}
            <div className="d-flex" style={{width: '100%'}}>
              <span>
                {/* Replace this with your actual arrow icon */}
                <i className="arrow-icon"></i>
              </span>
              <div
                className=""
                style={{
                  flexBasis: '56%',
                  paddingRight: '4%',
                  wordBreak: 'break-all',
                  whiteSpace: 'normal',
                }}>
                {props.item.menu_name}
              </div>
              {props.type === 'allMenus' && (
                <div
                  className=""
                  style={{
                    flexBasis: '50%',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}>
                  {props.item.role_alias}
                </div>
              )}
              <div className="designation-custom-icon">
              <i className="fas fa-grip-vertical" style={{color: '#67db1a'}}></i>
              </div>
              <div
                style={{position: 'absolute', right: '15px'}}
                className="menu-close-icon"
                onClick={() => {
                  props.removeMenus(props.item);
                }}>
                <i
                  class="fa-solid fa-xmark"
                  style={{
                    color: '#d71616',
                    fontWeight: 'bold',
                    right: '8px',
                  }}></i>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            {props.btn === 'btn' && (
              <>
                <div className="menus_btns">
                  <button
                    className="accordion-btn"
                    // onClick={() => {
                    //   props.slider?.current?.slickNext();
                    // }}

                    style={{backgroundColor: '#833491'}}>
                    Scheduler
                  </button>

                  <button
                    className="accordion-btn ml-2"
                    // onClick={() => {
                    //   props.slider?.current?.slickNext();
                    // }}

                    style={{backgroundColor: '#E13160'}}>
                    Services
                  </button>

                  <button
                    className="accordion-btn ml-2"
                    // onClick={() => {
                    //   props.slider?.current?.slickNext();
                    // }}

                    style={{backgroundColor: '#52BD41'}}>
                    Sales
                  </button>
                </div>

                {(props.item.menu_name === 'Meetups' ||
                  props.item.menu_name === 'Scheduler' ||
                  props.item.menu_name === 'Recruitment' ||
                  props.item.menu_name === 'Messages' ||
                  props.item.menu_name === 'Circles' ||
                  props.item.menu_name === 'Business Profile' ||
                  props.item.menu_name === 'Payments' ||
                  props.item.menu_name === 'Offerings') && (
                  <div className="mt-2">
                    <h1 style={{color: '#262626'}}>Permissions</h1>
                    {props.item.menu_name !== 'Business Profile' &&
                      props.item.menu_name !== 'Payments' && (
                        <div className="mt-2">
                          <Form.Group className="">
                            <Form.Check
                              //   onChange={e => setMakeDefault(e.target.checked)

                              type={'checkbox'}
                              id="save-my-card"
                              label={` Create ${props.item.menu_name}`}
                              value={props.item.menu_permissions?.create}
                              checked={props.item.menu_permissions?.create}
                              onChange={e => {
                                props.handleEditPermisions(
                                  props.item.menu_id,
                                  'create',
                                  props.item,
                                );
                              }}
                            />
                          </Form.Group>

                          {/* <span> Create {props.item.menu_name}</span> */}
                        </div>
                      )}
                    {props.item.menu_name !== 'Business Profile' && (
                      <div className="mt-2">
                        <Form.Group className="">
                          <Form.Check
                            //   onChange={e => setMakeDefault(e.target.checked)

                            type={'checkbox'}
                            id="save-my-card"
                            label={` Show  Analytics Row`}
                            value={props.item.menu_permissions?.analytics_row}
                            checked={props.item.menu_permissions?.analytics_row}
                            onChange={e => {
                              props.handleEditPermisions(
                                props.item.menu_id,
                                'analytics_row',
                                props.item,
                              );
                            }}
                          />
                        </Form.Group>

                        {/* <span> Create {props.item.menu_name}</span> */}
                      </div>
                    )}

                    {props.item.menu_name === 'Messages' && (
                      <div className="mt-2">
                        <Form.Group className="">
                          <Form.Check
                            //   onChange={e => setMakeDefault(e.target.checked)

                            type={'checkbox'}
                            id="save-my-card"
                            label={`Show Live Chat`}
                            value={props.item.menu_permissions?.live_chat}
                            checked={props.item.menu_permissions?.live_chat}
                            onChange={e => {
                              props.handleEditPermisions(
                                props.item.menu_id,
                                'live_chat',
                                props.item,
                              );
                            }}
                          />
                        </Form.Group>

                        {/* <span> Create {props.item.menu_name}</span> */}
                      </div>
                    )}
                    {props.item.menu_name !== 'Messages' &&
                      props.item.menu_name !== 'Scheduler' &&
                      props.item.menu_name !== 'Offerings' &&
                      props.item.menu_name !== 'Circles' &&
                      props.item.menu_name !== 'Business Profile' &&
                      props.item.menu_name !== 'Payments' && (
                        <div className="mt-2">
                          <Form.Group className="">
                            <Form.Check
                              //   onChange={e => setMakeDefault(e.target.checked)

                              type={'checkbox'}
                              id="save-my-card"
                              label={` Show  Customer Row`}
                              value={props.item.menu_permissions?.customer_row}
                              checked={
                                props.item.menu_permissions?.customer_row
                              }
                              onChange={e => {
                                props.handleEditPermisions(
                                  props.item.menu_id,
                                  'customer_row',
                                  props.item,
                                );
                              }}
                            />
                          </Form.Group>

                          {/* <span> Create {props.item.menu_name}</span> */}
                        </div>
                      )}
                    {props.item.menu_name === 'Business Profile' && (
                      <div className="mt-2">
                        <Form.Group className="">
                          <Form.Check
                            //   onChange={e => setMakeDefault(e.target.checked)

                            type={'checkbox'}
                            id="save-my-card"
                            label={` Show  Edit Company`}
                            value={props.item.menu_permissions?.edit_company}
                            checked={props.item.menu_permissions?.edit_company}
                            onChange={e => {
                              props.handleEditPermisions(
                                props.item.menu_id,
                                'edit_company',
                                props.item,
                              );
                            }}
                          />
                        </Form.Group>

                        {/* <span> Create {props.item.menu_name}</span> */}
                      </div>
                    )}

                    {props.item.menu_name === 'Business Profile' && (
                      <div className="mt-2">
                        <Form.Group className="">
                          <Form.Check
                            //   onChange={e => setMakeDefault(e.target.checked)

                            type={'checkbox'}
                            id="save-my-card"
                            label={`Show Add Pocs`}
                            value={props.item.menu_permissions?.add_poc}
                            checked={props.item.menu_permissions?.add_poc}
                            onChange={e => {
                              props.handleEditPermisions(
                                props.item.menu_id,
                                'add_poc_for',
                                props.item,
                              );
                            }}
                          />
                        </Form.Group>

                        {/* <span> Create {props.item.menu_name}</span> */}
                      </div>
                    )}

                    {props.item.menu_name === 'Payments' && (
                      <div className="mt-2">
                        <Form.Group className="">
                          <Form.Check
                            //   onChange={e => setMakeDefault(e.target.checked)

                            type={'checkbox'}
                            id="save-my-card"
                            label={` Create Invoices`}
                            value={props.item.menu_permissions?.create}
                            checked={props.item.menu_permissions?.create}
                            onChange={e => {
                              props.handleEditPermisions(
                                props.item.menu_id,
                                'create',
                                props.item,
                              );
                            }}
                          />
                        </Form.Group>

                        {/* <span> Create {props.item.menu_name}</span> */}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default CustomAccordion;
